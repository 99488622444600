<template>
    <div class="tabs is-medium">
        <ul>
            <li
                :class="{
                    'is-active': $route.name === 'account.settings',
                }"
            >
                <router-link :to="{ name: 'account.settings' }">
                    Profile
                </router-link>
            </li>
            <li
                :class="{
                    'is-active': $route.name === 'account.settings.sites',
                }"
            >
                <router-link :to="{ name: 'account.settings.sites' }">
                    Sites
                </router-link>
            </li>
            <li
                :class="{
                    'is-active': $route.name === 'account.settings.script',
                }"
            >
                <router-link :to="{ name: 'account.settings.script' }">
                    Recording Script
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'Tabs',
}
</script>

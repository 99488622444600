<template>
    <default-layout class="accSitesPage">
        <Tabs />
        <article class="message is-info">
            <div class="message-body">
                List the domains you wish to record (e.g.
                <strong>example.com</strong>, <strong>www.example.com</strong>,
                <strong>staging.example.com</strong>,
                <strong>app.example.com</strong>). Wildcard subdomains are not
                allowed (e.g. <strong>*.example.com</strong>).
            </div>
        </article>
        <div
            class="field is-grouped"
            v-for="(field, index) in form"
            :key="index"
        >
            <div class="control">
                <input
                    type="text"
                    class="input"
                    placeholder="www.example.com"
                    :class="{
                        'is-success': field.saved,
                        'is-danger': field.error,
                    }"
                    :value="field.value"
                    maxlength="50"
                    @change="handleInputChange($event.target.value, index)"
                />
                <div class="help is-success" v-show="field.saved">
                    <i class="fas fa-check"></i> Saved
                </div>
                <div class="help is-danger" v-show="field.error">
                    <i class="fas fa-times"></i> This format is not allowed.
                </div>
            </div>
            <div class="control">
                <a class="button is-white" @click="remove(index)">
                    <i class="fas fa-trash"></i>
                </a>
            </div>
        </div>
        <b-button
            type="is-primary"
            class="mt-3"
            icon-left="plus"
            @click="add()"
        >
            Add Site
        </b-button>
    </default-layout>
</template>

<script>
import Tabs from './Tabs'

export default {
    name: 'Sites',
    components: {
        Tabs,
    },
    data() {
        return {
            form: [],
        }
    },
    async created() {
        this.$loading.show()
        const { success, data } = await this.$api.get('/v1/account/sites')
        if (success) {
            data.forEach(site => this.add({ value: site, isNew: false }))
        } else {
            this.$toast.error()
        }
        this.$loading.hide()
    },
    methods: {
        add({ value = '', isNew = true } = {}) {
            this.form.push({
                value,
                isNew,
                origValue: value,
                saved: false,
                error: false,
            })
        },
        remove(index) {
            const { isNew } = this.form[index]
            this.form.splice(index, 1)
            !isNew && this.save()
        },
        validDomain(domain) {
            return (
                // Local hostname (with or without port number)
                /^[\w\d]+(:\d{1,5})?$/.test(domain) ||
                // Domain name (with or without port number)
                /^([\w\d]+(-[\w\d]+)*\.)+[\w]{2,}(:\d{1,5})?$/.test(domain)
            )
        },
        handleInputChange(value, index) {
            const protocolIndex = value.indexOf('://')
            const newValue = value
                .substring(protocolIndex === -1 ? 0 : protocolIndex + 3)
                .trim()
            const valid = this.validDomain(newValue)
            if (valid) {
                const changed = newValue !== this.form[index].origValue
                this.form.splice(index, 1, {
                    value: newValue,
                    origValue: newValue,
                    saved: changed,
                    error: false,
                })
                changed && this.save()
            } else {
                this.form.splice(index, 1, {
                    ...this.form[index],
                    value,
                    error: true,
                    saved: false,
                })
            }
        },
        async save() {
            const { success } = await this.$api.post('/v1/account/sites', {
                sites: JSON.stringify(this.form.map(({ value }) => value)),
            })
            if (success) {
                this.form = this.form.map(field => ({
                    ...field,
                    isNew: false,
                }))
            } else {
                this.$toast.error()
            }
        },
    },
}
</script>
